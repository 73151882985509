.footer {
    background-color: #1A1919;
    box-sizing: border-box;
    width:  100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
    padding: 30px;
}
.footer .footerGap {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.footer .profile {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.footer .contact {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    
}
.footer h3 {
    color: #FFFFFF;
    font-size: 16px;
}
.footer a {
    color: #FFFFFF;
    font-size: 15px;
    text-decoration: none;
}
.contact .contactIcon {
    display: flex;
    flex-direction:row;
    gap: 18px;
    align-items: center;
    justify-content: center;
}
.contactIcon i {
    font-size: 22px;
    color: #FFFFFF;
}
@media (max-width:786px) {
    .footer {
        flex-direction: column;
        text-align: center;
        gap: 37px;
    }
    
}