* {
    margin: 0;
    padding: 0;
}
body {
    background-color: #121212F2;
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}
